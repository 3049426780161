@font-face {
    font-family: roboto-black;
    src: url(./assets/static/fonts/Roboto-Black.ttf);
}
@font-face {
    font-family: roboto-regular;
    src: url(./assets/static/fonts/Roboto-Regular.ttf);
}
@font-face {
    font-family: roboto-medium;
    src: url(./assets/static/fonts/Roboto-Medium.ttf);
}
@font-face {
    font-family: roboto-thin;
    src: url(./assets/static/fonts/Roboto-Thin.ttf);
}
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: roboto-regular;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.variant {
    border: 1px solid #c1c1c1;
    color: #000;
    border-radius: 50%;
    padding: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-radio-wrapper-checked .variant {
    border: 2px solid #1677ff;
    border-radius: 50%;
    padding: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.custom_radio .ant-radio {
    display: none !important;
}
.custom_radio_text span:last-child{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.custom_radio_text .ant-radio {
    display: none !important;
}
.ant-popover-title{
    text-align: center;
}

.true_answer {
    border: 2px solid #0064008b;
    border-radius: 12px;
    padding: 5px;
    margin: 5px 0 5px 0;
    width: 100%;
    position: relative;
}
.false_answer {
    border: 2px solid #ff0000;
    border-radius: 12px;
    padding: 5px;
    margin: 5px 0 5px 0;
    width: 100%;
    position: relative;
}
.selected_answer {
    border: 2px solid #d2d2d25c;
    border-radius: 12px;
    padding: 5px;
    margin: 5px 0 5px 0;
    width: 100%;
    position: relative;
}
.true_answer .variant{
    border: 2px solid #0064008b;
}
.false_answer .variant{
    border: 2px solid #ff0000;
}


.custom_quil_heading h1{
    font-weight: normal;
}
.custom_quil_heading h2{
    font-weight: normal;
}
.custom_quil_heading h3{
    font-weight: normal;
}
.custom_quil_heading{
    font-family: "Times New Roman", Times, serif;
}
.ant-spin{
    z-index: 1001!important;
    background: #f1f1f1;
    opacity: 0.4;
    min-height: 100vh;
}
:where(.css-dev-only-do-not-override-1n7nwfa).ant-spin-nested-loading .ant-spin-container::after{
    z-index: 1001!important;
}