.login{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0095be;
}
.loginForm{
    width: 350px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px 30px ;
    background: #132841;
    border-radius: 10px;
}