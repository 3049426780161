.tables {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
}
.mid p {
    margin: 0;
}
.mid p span {
    font-size: 18px;
    font-weight: 500;
}
.mid {
    min-width: 150px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mid_list_area {
    min-width: 150px;
    /* border: 1px solid; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 7px;
}
.mid_list_area div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mid_list_area div span{
    font-weight: 500;
}
.remove{
    width: 20px;
    height: 20px;
    background-color: rgb(127, 244, 255);
    border-radius: 25%;
    cursor: pointer;
    user-select: none;
}
.mid_buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}