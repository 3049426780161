.common {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.top {
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 16px;
    background-color: #132841;
    color: #fff;
}
.top h4 {
    font-size: 16px;
    font-weight: 400;
}

.cont {
    width: 100%;
    display: flex;
    flex: 1;
}
.left {
    width: 50%;
    padding: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.leftImg {
    display: flex;
    justify-content: start;
    /* width: 100%; */
    max-width: 100%;
}
.rightImg {
    width: 100%;
    max-width: calc(100% - 35px);
}
.right {
    width: 50%;
    height: 80vh;
    padding: 50px;
    overflow-y: scroll;
}
.radioGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* overflow-y: scroll; */
}

.radio {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 5px;
    margin: 5px 0 5px 0;
    width: 100%;
    position: relative;
}
.selected_radio {
    border: 2px solid #1677ff;
    border-radius: 12px;
    padding: 5px;
    margin: 5px 0 5px 0;
    width: 100%;
    position: relative;
}

.end {
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 10px 0 10px;
}

@media (max-width: 1024px) {
    .cont {
        display: block;
        width: 100%;
    }
    .left {
        width: 100%;
    }
    .right {
        width: 100%;
    }
}
.false_answer {
    border-color: #ff0000;
}
.true_answer {
    border-color: #006400;
}
.selected_answer {
    border-color: #808080;
}

.exam_buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.select_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
}
.select_box_form {
    width: 400px;
}

.has_text_question {
    max-width: 100%;
    height: auto;
    margin-bottom: 40px;
}
.exam_radio {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 0;
}
.question_settings {
    background-color: #d3d3d36b;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 5px 0 ; */
    padding-right: 10px;
    border-bottom: 1px dashed #333;
    margin-bottom: 10px;
}
.current_number {
    background-color: #1677ff;
    color: #fff;
    font-size: 18px;
    padding: 10px 10px;
    height: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    user-select: none;
}
.mark_question {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.mark {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;
    cursor: pointer;
    user-select: none;
}
.saved_mark {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0;
    cursor: pointer;
    font-weight: 500;
    user-select: none;
}
.icon {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    width: 34px;
    height: 44px;
}
.saved_mark .icon {
    color: #1677ff;
    width: 34px;
    height: 44px;
    fill: #1677ff;
}
.icon_selected {
    color: #1677ff;
    font-size: 18px;
    font-weight: 600;
}

.answers_list {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 10px;
}

.remove_variant {
    border: 1px solid #c1c1c1;
    color: #260e0e;
    border-radius: 50%;
    padding: 10px;
    width: 25px;
    height: 25px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remove_variant_icon {
    font-size: 20px;
    font-weight: 600;
    margin-right: 10px;
    margin-left: 10px;
    color: #c1c1c1;
    cursor: pointer;
    width: 18px;
}
.remove_variant_text {
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    margin-left: 10px;
    color: #c1c1c1;
    cursor: pointer;
    width: 18px;
}
.question_remove_border {
    width: 102%;
    height: 1px;
    background-color: #333;
    position: absolute;
    left: -1%;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
}
.popover {
    padding-top: 10px;
}
.popover_position {
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    padding: 10px 0;
    margin-bottom: 20px;
}
.popover_position {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.popover_item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}
.popover_icon {
    width: 20px;
    height: 20px;
    fill: #ff0000;
}
.popover_item_icon {
    width: 20px;
    height: 20px;
    background-color: #86b5f74f;
    border: 1px solid #1677ff63;
    margin-right: 3px;
}
.popover_item_icon2 {
    width: 20px;
    height: 20px;
    border: 1px solid #1677ff;
    background: #1677ff;
    margin-right: 3px;
}
.text_question_image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-self: flex-start;
}

.openAns {
    display: flex;
    align-items: start;
    gap: 10px;
    font-weight: normal;
    flex-direction: column;
}
.openAns h4 {
    font-weight: normal;
}
.openAns div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.preview span:last-child {
    min-width: 20px;
    min-height: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.custom_modal {
    position: absolute;
    left: 1%;
    bottom: 5%;
}
.calculator_header {
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 5px;
    height: 30px;
}
.calculator_header span {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1677ff;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    border-radius: 50%;
    cursor: pointer;
    padding-bottom: 6px;
}

.calculator_settings {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
}
.settings_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.settings_div:hover {
    color: #1677ff !important;
}
.top_items {
    flex: 1 1 0;
    width: 0;
}

.exam_name {
    text-align: left;
}

.exam_over_modal {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10%;
}

.exam_over {
    width: 400px;
    height: 400px;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
    flex-direction: column;
}

.exam_over_icon {
    font-size: 80px;
    color: #1677ff;
}
.exam_over h4 {
    font-size: 24px;
}
.finish_exam_modal {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10%;
}
.finish_exam {
    width: 440px;
    height: 200px;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    /* gap: 30px; */
    justify-content: center;
    flex-direction: column;
}
.finish_exam p {
    margin-top: 0;
    font-size: 24px;
}
.finish_exam div {
    display: flex;
    gap: 20px;
}
.top_logo_bar {
    display: flex;
    align-items: center;
    gap: 10px;
}
.top_logo_bar img{
    height: 60px;
}
