.uploadButton{
    border: 1px solid;
    padding: 40px 100px;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
    width: 400px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.uploadArea{
    border: 1px solid;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
    width: 400px;
    font-size: 16px;
}

