.add_question{
    display: flex;
    gap: 50px;
    margin-top: 40px;
}
.left {
    width: 35%;
    padding-right: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.19);
}
.right {
    width: 65%;
}
.progress_area{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
}
.selectText{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 30px;
}
.select_box{
    display: flex;
    align-items: center;
    justify-content: center;
}
.select_box_form{
    width: 400px;
}