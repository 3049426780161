.report {
    display: flex;
    align-items: center;
    justify-content: center;
}

.begin {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5%;
    flex-direction: column;
}
.select_box_form {
    width: 400px;
}
.exam_radio {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 0;
}
.selected_radio {
    border: 2px solid #1677ff;
    border-radius: 12px;
    padding: 5px;
    margin: 5px 0 5px 0;
    width: 100%;
    position: relative;
}
.radioGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    /* overflow-y: scroll; */
}

.radio {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 5px;
    margin: 5px 0 5px 0;
    width: 100%;
    position: relative;
}
.true_answer {
    border: 2px solid #006400;
    border-radius: 12px;
    padding: 5px;
    margin: 5px 0 5px 0;
    width: 100%;
    position: relative;
}
.false_answer {
    border: 2px solid #ff0000;
    border-radius: 12px;
    padding: 5px;
    margin: 5px 0 5px 0;
    width: 100%;
    position: relative;
}
.selected_answer {
    border: 2px solid #d2d2d25c;
    border-radius: 12px;
    padding: 5px;
    margin: 5px 0 5px 0;
    width: 100%;
    position: relative;
}

.openAnswers {
    text-align: left;
    font-size: 16px;
    margin: 10px 0;
}
.openAnswers div span:last-child {
    font-weight: 500;
    font-size: 18px;
}

.text_question{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.show_result{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
.student{
    font-size: 16px;
}
.student span{
    font-size: 18px;
    font-weight: 500;
}

.result_header{
    display: flex;
    gap: 10px;
    margin: 10px 0 2px 0;
    font-size: 16px;
}
.result_main{
    padding: 5px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
}
.result_main:last-child{
    border: none;
}
.result_number{
    display: flex;
    gap: 20px;
}
.result_number span{
    font-weight: 500;
    font-size: 20px;
}