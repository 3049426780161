.progress {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 600px;
    gap: 7px;
}
.progress_item {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1677ff63;
    color: #010101;
    cursor: pointer;
    position: relative;
}
.progress_disabled_item {
    background-color: #bdd9ff4f;
    opacity: 0.4;
    cursor: not-allowed;
}
.progress_order {
    background-color: #1677ff!important;
    color: #fff;
    /* opacity: 0.4; */
}

.progress_item:hover {
    border: 1px solid #1677ffc8;
}
.progress_item_active {
    border: 1px solid #1677ffc8;
    background: #86b5f74f;
    color: #000;
}
.progress_mark_icon {
    position: absolute;
    top: -25%;
    right: -25%;
    width: 14px;
    height: 15px;
    padding-bottom: 2px;
    fill: #ff0000;
    background-color: #fff;
}

.true_answer {
    background-color: #0064005c;
    color: #fff;
    border: none;
}
.false_answer {
    background-color: #ff0000b0;
    color: #fff;
    border: none;
}
.progress_item_check_answer_active{
    border: 3px solid #359aff;
}