.page_container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: stretch;
}

.App {
    display: flex;
    text-align: center;
    width: 100%;
    min-height: 100vh;
}
.main_left {
    width: 66px;
    min-height: 100vh;
    z-index: 4;
    background: #2d6e90;
}
.main_right {
    width: calc(100% - 66px);
}

.navbar {
    width: 100%;
    height: 70px;
    background-color: #eceff2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    gap: 40px;
}
.login {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    background-color: rgb(0, 108, 159);
    padding: 10px;
    border-radius: 8px;
}
.main_container {
    width: 100%;
    padding: 20px 40px;
}
.sidebar {
    margin-top: 70px;
}
.sidebar ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.sidebar ul a {
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.37);
    text-decoration: none;
    color: #fff !important;
}
.sidebar ul a:hover {
    background-color: rgba(255, 255, 255, 0.125);
}
