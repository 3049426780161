.upload_item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 10px;
    width: 100%;
}
.answerText_Radio{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 30px;
}