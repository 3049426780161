.module_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.table_buttons{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.add_form{
    margin-top: 30px;
    text-align: center;
}
.add_text_modal{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}