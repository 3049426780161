.dashboard{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
}
.dashboard > div {
    width: calc(50% - 20px);
}
.module_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 1400px){
    .dashboard > div {
        width: 100%;
    }
}